function doPortfolio() {

    var to = null;

    $(".lstPortfolio>li").each(
        function()
        {
            $(this).data('current', 0);
            $("img:gt(0)", $(this)).hide();
        }
    );

    $(".lstPortfolio>li").mouseenter(
        function()
        {
            clearTimeout(to);
            rotateImage($(this));
        }
    ).mouseleave(
        function()
        {
            clearTimeout(to);
        }
    );

    function rotateImage(li)
    {
        if ($("img", li).length > 1) {
            var index = li.data('current');
            var cur = $("img:eq(" + index + ")", li);
            index++;
            var next = $("img:eq(" + index + ")", li);

            if (next.length == 0)
            {
                index = 0;
                var next = $("img:eq(" + index + ")", li);
            }

            li.data('current', index);

            cur.hide();
            next.show();

            to = setTimeout(function(){rotateImage(li)}, 1200);

        }
    }
}
module.exports = doPortfolio;