function doPortfolioTags() {

    //Start first item.
    if($(".lstTags li.active").length == 1)
    {
        var aActiveTags = new Array();
        $(".lstTags li.active").each(
            function()
            {
                aActiveTags.push($(this).data("tag"));
                $("h2#activeTag").text($(this).text());
            }
        );
        doTags(aActiveTags);
    }


    $("select.selectTags").change(
        function()
        {
            var aActiveTags = new Array();
            $("select.selectTags option:selected").each(
                function()
                {
                    aActiveTags.push($(this).data("tag"));
                    $("h2#activeTag").text($(this).text());
                }
            );

            doTags(aActiveTags);
        }
    )
    $(".lstTags li a").click(
        function(e)
        {
            e.preventDefault();

            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
            } else {
                $(".lstTags li.active").removeClass('active');
                $(this).parent().addClass('active');
                $("h2#activeTag").text($(this).text());
            }

            var aActiveTags = new Array();
            $(".lstTags li.active").each(
                function()
                {
                    aActiveTags.push($(this).data("tag"));
                }
            );

            doTags(aActiveTags);
        }
    );

    function doTags(aActiveTags) {
        if (aActiveTags.length == 0) {
            $("ul.lstPortfolio>li").show().animate({"width": 240, "height": 221});
        } else {
            $("ul.lstPortfolio>li").each(
                function () {
                    var bCorrect = true;
                    for (var i = 0; i < aActiveTags.length; i++) {
                        if ($(this).data("tags").indexOf(aActiveTags[i]) < 0) {
                            bCorrect = false;
                        }
                    }

                    if (!bCorrect) {
                        $(this).animate({"width": 0, "height": 0},
                            function()
                            {
                                $(this).hide();
                            });
                    } else {
                        $(this).show().animate({"width": 240, "height": 221});
                    }
                }
            );
        }
    }
}
module.exports = doPortfolioTags;